import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import DataExplorerContainer from "./DataExplorer/dataExplorerContainer";

const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
`;

export default function TotalConversionsExplorer() {
  const data = [{
		title: "Sorted by Breakdown",
		query: "byBreakdownCombinedAndSorted",
		explorer: "conversationsbyBreakdownAndSorted",
		requestType: "conversationExplorer",
		ignorePeriod: true
	},
	{
		title: "Sorted by Source",
		query: "bySourceCombinedAndSorted",
		explorer: "conversationsbySourceAndSorted",
		requestType: "conversationExplorer",
		ignorePeriod: true
	},
	{
		title: "Sorted by Source and Breakdown",
		query: "bySourceAndByBreakdownCombinedAndSorted",
		explorer: "conversationsbySourceAndByBreakdownSorted",
		requestType: "conversationExplorer",
		ignorePeriod: true
	}
]
  
  return (
  	<Container>
		<h1>
			Total Conversions 
		</h1>
		<div>
		{data.map((item, idx) => {
			return (
			<div key={(idx + item.query)}>
				<h2>{(item.ignorePeriod ? "" : "Daily")} {item.title}</h2>
				<DataExplorerContainer
				key={(idx + item.query)}
				date="dd"		
				query={item.query}
				explorer={item.explorer}
				requestType={item.requestType}
				ignorePeriod={item.ignorePeriod || false}
				/>
				<hr />
			</div>
			)
		})}
		</div>

    </Container>)
};