import { useQuery } from 'react-query'
import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { fetchUpgradesExplorer, fetchMobileStatusExplorer, fetchMobileStatus, fetchSignUpsExplorer, fetchPaidExplorer, fetchJobsExplorer, fetchConversationsExplorer } from "../../api/dashStats"
import CwbDataExplorer from './cwbDataExplorer';


function DataExplorerContainer(props) {
  const {date, query, explorer, requestType, ignorePeriod } = props;
  const request = {
    paidExplorer: fetchPaidExplorer,
    conversationExplorer: fetchConversationsExplorer
  }

  async function fetch() {
    const target = request[requestType];
    const { data } = await target(query, date)
    return data.result
  }


  const { data, error, isError, isLoading } = useQuery([explorer, ignorePeriod ?  (date || "week") : ''], fetch);
  const dataProps = { data, error, isError, isLoading, date, query, explorer };
  return <CwbDataExplorer  {...dataProps } />
}
export default DataExplorerContainer;