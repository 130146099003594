import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import DataExplorerContainer from "./DataExplorer/dataExplorerContainer";

const Container = styled.div`
  width: 80%;
  margin:24px auto;
  .iReLkr .ReactTable * {
    text-align:left
  }
`;

export default function ConversationExplorer() {
  const data = [{
		title: "By Source",
		query: "byPlatform",
		explorer: "conversationsByPlatform",
		requestType: "conversationExplorer"
	},
	{
		title: "By Breakdown",
		query: "byBreakdown",
		explorer: "conversationsByBreakdown",
		requestType: "conversationExplorer"
	},
	{
		title: "By Source and Breakdown",
		query: "byPlatformAndBreakdown",
		explorer: "conversationsByPlatformAndBreakdown",
		requestType: "conversationExplorer"
	},
	{
		title: "Sorted by Breakdown",
		query: "byBreakdownAndSorted",
		explorer: "conversationsbyBreakdownAndSorted",
		requestType: "conversationExplorer",
		ignorePeriod: true
	}
]
  
  return (
  	<Container>
		<h1>
			Conversations 
		</h1>
		<div>
		{data.map((item, idx) => {
			return (
			<>
				<h2>{(item.ignorePeriod ? "" : "Daily")} {item.title}</h2>
				<DataExplorerContainer
				key={idx}
				date="dd"		
				query={item.query}
				explorer={item.explorer}
				requestType={item.requestType}
				ignorePeriod={item.ignorePeriod || false}
				/>
				<hr />
			</>
			)
		})}
		</div>

    </Container>)
};