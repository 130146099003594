import { useQuery } from 'react-query'
import React, { Component } from "react";
import styled from "styled-components";
import { fetchUpgradesExplorer } from "../../api/dashStats"
import { DataExplorer, Toolbar, Viz } from "@nteract/data-explorer";
import csvDownload from 'json-to-csv-export'

const CwbDataExplorer = (props) => {
    const { isLoading, isError, error, data, exportFileName } = { ...props };

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (isError) {
        return <div>Error! {error.message}</div>
    }
    if (!data.data) {
        data.data = [];
    }
    return (
        <div>
            {
                <DataExplorer data={data}>
                    
                    <Viz/>
                   
                    <Container>

                        &nbsp;
                        |
                        &nbsp;

                        <ExportButton onClick={() => csvDownload(data.data, (exportFileName || `${new Date().toISOString().split('T')[0]}-metrics.csv`))}>Export to CSV</ExportButton>
                    </Container>

                    <Toolbar />
                </DataExplorer>
            }
        </div>
    )
}

const Container = styled.div`
    position: absolute;
    margin-left: 95px;
`;

const ExportButton = styled.button`
    margin-left: 5px;
`

export default CwbDataExplorer