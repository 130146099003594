import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from 'react-router-dom';

class Guide extends Component {
  state = {
    data: []
  }

  render() {
    const { match, location, history } = this.props;

    return <Container>
      <h1>
        Guide
      </h1>
      <div>
        <h2>All new CWB Registration metrics</h2>
        <LinkList>
          <li><a href="/signups-explorer" onClick={(ev) => ev.preventDefault() || history.push("/signups-explorer")}>New Sign Ups metrics</a></li>
          <li><a href="/signupsByRegion-explorer" onClick={(ev) => ev.preventDefault() || history.push("/signupsByRegion-explorer")}>New Sign Ups metrics by Region </a></li>
        </LinkList>
        <h2>Paid only CWB metrics</h2>
        <LinkList>
          <li><a href="/paid-explorer" onClick={(ev) => ev.preventDefault() || history.push("/paid-explorer")}>Only paid new Sign Ups metrics</a></li>
          <li><a href="/upgrades-explorer" onClick={(ev) => ev.preventDefault() || history.push("/upgrades-explorer")}>Upgrade to PRO metrics</a></li>
          <li><a href="/jobs-explorer" onClick={(ev) => ev.preventDefault() || history.push("/jobs-explorer")}>Jobs explorer (PAYG)</a></li>
        </LinkList>
        <h2>CWBLabs metrics</h2>
        <LinkList>
          <li><a href="/signupsLabs-explorer" onClick={(ev) => ev.preventDefault() || history.push("/signupsLabs-explorer")}>All Labs Metrics</a></li>
        </LinkList>
        <h2>Conversation Metrics</h2>
        <LinkList>
          <li><a href="/converations-explorer" onClick={(ev) => ev.preventDefault() || history.push("/converations-explorer")}>Conversation by Day</a></li>
          <li><a href="/conversions-combined-explorer" onClick={(ev) => ev.preventDefault() || history.push("/conversions-combined-explorer")}>Conversions by Day Combined <b>(new)</b></a></li>
          <li><a href="/total-conversions" onClick={(ev) => ev.preventDefault() || history.push("/total-conversions")}>Total Conversions <b>(new)</b></a></li>
        </LinkList>

      </div>
    </Container>
  }
}
const Container = styled.div`
  width: 80%;
  margin:24px auto;
`;

const LinkList = styled.ul`
    font-size:1.3rem;
`


export default withRouter(Guide);