import axios from "axios";

export const getInstance = () => {
    const baseUrl = process.env.REACT_APP_NODEBACKEND;
    const token = localStorage.getItem("CWB_TOKEN");

    const authStr = 'Bearer '.concat(token);
    return axios.create({ baseURL: baseUrl, headers: { Authorization: authStr } });
}

export const fetchMobileStatusExplorer = () => {
    const url = `/mobile_status-explorer`;
    return getInstance().get(url);
}

export const fetchMobileStatus = () => {
    const url = `/mobile_status`;
    return getInstance().get(url);
}

export const fetchSignUpsExplorer = (subQuery, date) => {    
    const url = `/signups-explorer/${subQuery}/${date ? date : 'week'}`;
    return getInstance().get(url);
}

export const fetchPaidExplorer = (subQuery, date) => {    
    const url = `/paid_signups-explorer/${subQuery}/${date ? date : 'week'}`;
    return getInstance().get(url);
}

export const fetchUpgradesExplorer = (subQuery, date) => {
    const url = `/upgrades-explorer/${subQuery}/${date ? date : 'week'}`;
    return getInstance().get(url);
}

export const fetchJobsExplorer = (subQuery, date) => {
    const url = `/jobs-explorer/${subQuery}/${date ? date : 'week'}`;
    return getInstance().get(url);
}

export const fetchConversationsExplorer = (subQuery, date) => {
    const url = `/conversation-explorer/${subQuery}`;
    return getInstance().get(url);
}
